@import "variables/variables";

/* ---- login ---- */
.login{
    .checkbox{
        label{
            vertical-align: baseline;
            line-height: 34px;
        }
    }
    font-weight: 300;
    .form-wrap{
        max-width:320px;
        margin:180px auto auto auto;
        .info{
            text-align: center;
        }
        @media screen and (max-width: 768px){
            margin-top:50%;
        }
    }
    .form-group-submit{
        margin-top: 0;
    }

    form{
        margin-top:20px;

        .btn-big{
            width:100%;
        }
    }
}
.login-data{
}

.login-title{
    color: #000;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.3px;
}

.form-group{
    margin-bottom: 23px
}
.form-group-checkbox{
    margin-top: 5px;
    margin-bottom: 0;
}
.form-group-error{
    margin:10px auto;
    float:none;
    width:100%;
    padding: 9px 0 8px 11px;
    border-radius: 3px;
    border: 1px solid #ebafa6;
    background: #f5dbd9;
    color: #565656;
    font-size: 12px;
    text-align:left;
    img{
        position: absolute;
        top: 10px;
        left: -8px;
    }
    p{
        padding-left: 22px;
        letter-spacing: 0.3px;
        zoom: 0;
        background: url('#{$base_url}/images/icons/form-error.svg')  no-repeat left 0px;
        background-size: 15px 15px;
    }
}

.forgot-password{
    a{
        float: left;
        margin-top: 5px;
        color: #1570a6;
        font-size: 12px;
        letter-spacing: 0.3px;
    }
}

.login-banner{
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: url('#{$base_url}/images/login-banner-image.jpg') no-repeat;
    background-size: cover
}

.login{
    .help-block{
        position: relative;
        top:0;
        left: auto;
        padding:9px 0 8px 25px;
    }
}

.help-block{
    width:100%;
}

 .language-picker-wrapper {
    border: none;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
        
    .dropdown-toggle {
        padding-top: 3px;
        background-color: transparent;
        color: #333;
        border: none;
        border-bottom: 1px solid #9a9a9a;
        border-radius: 0;

        &:focus, &:hover, &:active {
            background-color: transparent;
            color: #333;
            transition: 300ms;
            box-shadow: none;
        }
    }
    
    .dropdown-menu {
        min-width: 55px;
        padding: 3px 11px 6px;
        
        li { 
        color: #333;  
        cursor: pointer;
        font-size: 13px;

        &:hover { text-decoration: underline; }
        }
    }
}
/* ---- /login ---- */